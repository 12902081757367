/* Home.css */
.upper-banner {
    background-image: url('../images/lemon.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 90vh; /* Adjust the height as necessary */
    filter: brightness(0.9);
    opacity: 0.9; /* Adjust opacity for subtlety */
    overflow: hidden;
}

.container {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 5px;
    box-sizing: border-box;
    justify-content: center; /* Center align the content */
    align-items: center; /* Center align the content vertically */
    height: auto;
}

.content {
    box-sizing: border-box;
    padding: 20px;
    margin: 10px;
    flex: 1;
    max-width: 500px; /* Limit the maximum width */
    height: auto;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 45%;
}

.popup {
    box-sizing: border-box;
    padding: 20px;
    margin: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 500px; /* Limit the maximum width */
    flex: 1;
}


/* Media query for screens smaller than 768px (e.g., phones) */
@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }

    .half-width {
        width: 100%;
    }
}

/* Media query for larger screens (e.g., laptops) */
@media (min-width: 768px) {
    .half-width {
        width: 45%; /* Adjust width as needed to fit your design */
    }

    .full-width {
        width: 100%;
    }

    .container {
        align-items: flex-start; /* Align content closer to the top */
    }
}

/* Media query for extra large screens (e.g., desktops) */
@media (min-width: 1200px) {
    .container {
        justify-content: space-between;
    }
}
