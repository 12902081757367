.nutritional-info {
    max-width: 1000px;
    margin: 40px auto;
    padding: 20px;
    background-color: #F9F9F9; /* Light background for contrast */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center;
}


.nutritional-parent {
    padding-bottom: 10px;
}

.approximation-guidelines {
    font-size: 0.5em; /* Smaller font size (you can adjust as needed) */
    text-align: left; /* Ensure the text is left-aligned */
    font-style: italic; /* Italicize the text */
    margin: 0; /* Remove default margin, if necessary */
    color: #555; /* Optional: use a muted color for the text */
}


.nutritional-info-container {
    width: 100%; /* Make sure the container takes full width */
    text-align: left; /* Center the text */
    /*padding: 20px 0; !* Optional padding *!*/
}

.nutritional-info-heading {
    position: relative;
    font-size: 1.5em; /* Adjust font size as needed */
    margin: 0;
    margin-top: -10px; /* Moves the heading higher up */
}

.nutritional-info-heading::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px; /* Thickness of the underline */
    background-color: #1D3557; /* Mediterranean Blue underline */
    width: 100%; /* Underline spans the full width of the text */
}

.nutrient-info {
    display: flex;
    justify-content: space-between; /* Distribute items evenly with space between */
    padding: 5px 0; /* Optional padding for spacing */
    border-bottom: 1px solid #ccc; /* Optional separator */
    text-align: left; /* Ensure left alignment */
}

.nutrient-label {
    color: #1D3557; /* Mediterranean Blue */
    font-size: 0.8em;
    text-align: left; /* Ensure left alignment */
    flex: 1; /* Allow the label to take up available space */
}

.nutrient-quantity {
    color: #457B9D; /* Lighter Blue for quantity */
    text-align: right;
    font-size: 0.8em;
    flex-shrink: 0; /* Prevent quantity from shrinking */
    margin-left: 10px; /* Add some space between label and quantity */
}


