.iframe-container {
    width: 1000px; /* Match the iframe's width */
    height: 970px; /* Subtract 30px from the iframe's height */
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.juicer-iframe {
    display: block;
    width: 1000px;
    height: 1000px;
    border: none;
    position: relative;
    top: -30px; /* Move the iframe content up by 30px */
}

/* Responsive adjustments for phones and smaller screens */
@media screen and (max-width: 768px) {
    .iframe-container,
    .juicer-iframe {
        width: 100%; /* Make the iframe container and iframe take up full width of the screen */
        height: auto; /* Allow the height to adjust automatically */
        min-height: 1000px; /* Set a minimum height to ensure content is visible */
    }

    .juicer-iframe {
        top: 0; /* Remove the negative top position to avoid clipping content */
    }
}
