.app-button {
    background-color: #FFD166; /* Sunlight Yellow background */
    color: #FFFFFF !important; /* Mediterranean Blue text color */
    text-align: center; /* Center align text */
    justify-content: center; /* Center align the content inside the button */
    border-radius: 8px; /* Slightly rounded corners */
    border: 2px solid #FFD166; /* Mediterranean Blue border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    width: fit-content;
    display: inline-flex; /* Ensures correct alignment */
    align-items: center; /* Vertically center content */
}

.app-button:hover {
    background-color: #1D3557; /* Mediterranean Blue background on hover */
    color: #FFFFFF !important; /* White text color */
    border-color: #1D3557; /* Change border to sunlight yellow on hover */
}
