.footer {
    background-color: #1D3557; /* Dark Blue background */
    color: #FFFFFF; /* White text */
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    margin-top: auto; /* Push the footer to the bottom of the page */

}

.footer a {
    color: #FFD166; /* Highlight color for the link */
    text-decoration: none;
    font-weight: bold;
}

.footer a:hover {
    color: #F4A261; /* Change color on hover */
    text-decoration: underline;
}
